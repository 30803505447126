var app = angular.module(
    'digininja',
    [
        'HabilidadesService',
		'ui.bootstrap.contextMenu',
        'ngCapsLock',
        'angular-inview',
        
        
    ]
).config(['$locationProvider', function($locationProvider){
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: true,
        rewriteLinks: false
    })
}]);

app.filter('highlight2', function() {
    function escapeRegexp(queryToEscape) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    return function(matchItem, query) {
        return query && matchItem ? matchItem.toString().replace(new RegExp(escapeRegexp(query), ''), '<span class="highlighted">$&</span>') : matchItem;
    };
})