
angular.module('digininja').controller('testeVelocidadeSiteCtrl', function($scope, $http, $timeout, $sce, $location){
    var vm = this;
    vm.googleChart = null;
    var idSequencialAtual = 1;
    vm.urlGif = '';
    vm.comparacoes = [
        {
            'titulo': 'Muitos emails para responder no dia',
            'voce': '5 emails',
            'ninja': '20 emails',
            'tipo': 'Q', // quantidade
            'descricao': 'emails',
            'min': 5,
            'max': 25
        },
        {
            'titulo': 'Entregar relatório difícil para o chefe',
            'voce': '10 horas',
            'ninja': '1 hora',
            'tipo': 'T', // tempo
            'descricao': 'horas',
            'min': 5,
            'max': 25
        },
        {
            'titulo': 'Trabalho complicado da faculdade',
            'voce': '3 horas',
            'ninja': '15 minutos',
            'tipo': 'T', // tempo
            'descricao': 'minutos',
            'min': 40,
            'max': 59
        },
        {
            'titulo': 'Clientes atendidos no WhatsApp Web',
            'voce': '2 clientes',
            'ninja': '15 clientes',
            'tipo': 'Q', // quantidade
            'descricao': 'clientes',
            'min': 20,
            'max': 80
        },
        {
            'titulo': 'Cadastrar atendimentos no sistema da empresa',
            'voce': '15 minutos',
            'ninja': '20 segundos',
            'tipo': 'T', // tempo
            'descricao': 'minutos',
            'min': 5,
            'max': 25
        },
        {
            'titulo': 'Postagens de blog digitadas em um dia',
            'voce': '2 postagens',
            'ninja': '20 postagens',
            'tipo': 'Q', // quantidade
            'descricao': 'postagens',
            'min': 5,
            'max': 25
        },
        {
            'titulo': 'Preencher fichas cadastrais de clientes',
            'voce': '10 minutos',
            'ninja': '2 minutos',
            'tipo': 'T', // tempo
            'descricao': 'horas',
            'min': 6,
            'max': 12
        },
        {
            'titulo': 'Linhas de código de programação digitadas em um dia',
            'voce': '3.000 linhas',
            'ninja': '6.000 linhas',
            'tipo': 'Q', // quantidade
            'descricao': 'linhas',
            'min': 2000,
            'max': 6000
        },
        {
            'titulo': 'Páginas do seu TCC feitas em 1 mês',
            'voce': '1 página',
            'ninja': '7 páginas',
            'tipo': 'Q', // quantidade
            'descricao': 'páginas',
            'min': 200,
            'max': 500
        },
    ];

    $scope.labels = ["Download Sales", "In-Store Sales", "Mail-Order Sales"];
    $scope.data = [300, 500, 100];

    vm.randomizarLista = function(array) {
        return array.sort(function() {
          return .5 - Math.random();
        });
    }

    vm.carregarCategorias = function() {
        $http.post('site/testes/carregar-categorias').then(
            function successCallback(response) {
                vm.categorias = response.data;
            },
            function errorCallback() {}
        );
    }

    vm.calcularComparacoes = function(velocidadeUsuario) {
        var velocidadeNinja = 500;

        for (var i = 0; i < vm.comparacoes.length; i++) {
            var comparacao = vm.comparacoes[i];
            
            // Quantidade normal, ninja faz mais
            if (comparacao.tipo == 'Q') {
                // Returns a random integer from min to max:
                var qtdeBase = Math.floor(Math.random() * comparacao.max) + comparacao.min; 
                comparacao.voce = qtdeBase + ' ' + comparacao.descricao;

                var qtdeNinja = Math.floor((qtdeBase / velocidadeUsuario) * velocidadeNinja);
                comparacao.ninja = qtdeNinja + ' ' + comparacao.descricao;
                comparacao.ninjaResultado = '+' + (qtdeNinja - qtdeBase) + ' ' + comparacao.descricao;
            }

            if (comparacao.tipo == 'T')
            {
                var qtdeBase = Math.floor(Math.random() * comparacao.max) + comparacao.min; 
                comparacao.voce = qtdeBase + ' ' + comparacao.descricao;

                var qtdeNinja = Math.floor((qtdeBase / velocidadeNinja) * velocidadeUsuario);
                comparacao.ninja = qtdeNinja + ' ' + comparacao.descricao;
                comparacao.ninjaResultado = '-' + (qtdeBase - qtdeNinja) + ' ' + comparacao.descricao;

            }
        }
    }

    vm.carregarIndicadores = function() {
        $http.post('site/testes/carregar-indicadores').then(
            function successCallback(response) {
                vm.qtdeResultados = response.data.qtde_resultados;
                vm.velocidadeMedia = Math.floor(response.data.velocidade_media);
                vm.qtdeUsuarios = response.data.qtde_usuarios;
            },
            function errorCallback() {}
        );

    }

    vm.selecionarCategoria = function(categoria) {
        vm.categoriaSelecionada = categoria;

        vm.estagio = 2;

        vm.carregarTestes(categoria);
    }
    
    vm.carregarTestes = function(categoria)
    {
        $http.post('site/testes/carregar', {categoria: categoria.codigo}).then(
            function successCallback(response) {
                vm.testesDisponiveis = response.data;
            },
            function errorCallback() {}
        );
    }
    vm.carregarCategorias();
    vm.carregarIndicadores();


    vm.aplicarGoogleChart = function() {
        $http.post('site/google-chart', {velocidade: vm.velocidadeAtual}).then(function successCallback(response) {
            var GoogleChart = function() {
                this.$body = $("body")
            };
        
            GoogleChart.prototype.createPieChart = function(selector, data, colors, is3D, issliced, slice) {
                var options = {
                    backgroundColor: 'transparent',
                    color: 'white',
                    fontName: 'Libre Franklin',
                    fontSize: 13,
                    height: 350,
                    width: 600,
                    chartArea: {
                        // left: 50,
                        width: '90%',
                        // height: '90%'
                    },
                    colors: colors,
                    legend: {
                        textStyle: {
                            color: 'white', 
                            fontSize: 16
                        }
                    },
                    // pieSliceText: 'label',
                    pieSliceTextStyle: {
                        // color: <string>, 
                        // fontName: <string>, 
                        fontSize: 11
                    }       
                };
        
                if(is3D) {
                    options['is3D'] = true;
                }
        
                if(issliced) {
                    options['is3D'] = true;
                    // options['pieSliceText'] = 'label';
                    options['slices'] = {};
                    options['slices'][slice] = {offset: 0.2};
                }
        
                var google_chart_data = google.visualization.arrayToDataTable(data);
                var pie_chart = new google.visualization.PieChart(selector);
                pie_chart.draw(google_chart_data, options);
                pie_chart.setSelection([{row: 1}]);
                // pie_chart.setSelection(2);
                return pie_chart;
            },
        
            GoogleChart.prototype.init = function () {
                var $this = this;
        
                var sliced_Data = response.data.dados;
        
                $this.createPieChart(
                    $('#3d-exploded-chart')[0], 
                    sliced_Data, 
                    ['#f5707a', '#f9c851', '#188ae2', '#6b5fb5', '#4bd396'], 
                    true, 
                    true,
                    response.data.destacar
                );
        
                $(window).on('resize', function() {
                    $this.createPieChart(
                        $('#3d-exploded-chart')[0], 
                        sliced_Data, 
                        ['#f5707a', '#f9c851', '#188ae2', '#6b5fb5', '#4bd396'], 
                        true, 
                        true, 
                        response.data.destacar

                    );
                });
            },
        
            $.GoogleChart = new GoogleChart, $.GoogleChart.Constructor = GoogleChart;
            
            google.load("visualization", "1", {packages:["corechart"]});
    
            //after finished load, calling init method
            google.setOnLoadCallback(function() {$.GoogleChart.init();});
        })
        
    }


    vm.avaliacao = 0;
    vm.feedback = '';
	vm.textoLabelFeedback = 'O que você achou?';
	vm.seCadastrouNoCurso = false;

    vm.url = $location.absUrl().replace(($location.url() == '/' ? '' : $location.url() == '/'), '');

    vm.menu = [
        {
            text: 'Tentar trapaçear',
            click: function ($itemScope, $event, modelValue, text, $li) {
                alert('Impossível');
            }
        },
    ];

    vm.mostrarInscricaoTopo = false;

    vm.email = {
        email: '',
        nome: ''
    };

    vm.agradecimentoEmail = false;

    vm.mostrarRanking = false;
    vm.carregandoTop10 = false;

    vm.compartilhamentoId = '';
    vm.nomeGrupoCompartilhamento = '';

    vm.carregarRanking = function()
    {
        vm.carregandoTop10 = true;
        $http.post('site/carregar-top10', {id_grupo: vm.compartilhamentoId}).then(function successCallback(response){
            vm.top10 = response.data;
            vm.carregandoTop10 = false;
        });
    }

    vm.diferencas = function (a, b)
    {
        var i = 0;
        var j = 0;
        var result = "";

        if (b != undefined)
            while (j < b.length)
            {
                if (a[i] != b[j] || i == a.length)
                {
                    result += '<span class="erro">'+(b[j] == ' ' ? '_' : b[j]) +'</span>';
                }
                else
                {
                    result += b[j];

                    i++
                }

                j++;
            }
        return result;
    }

    vm.gerarIdCompartilhamento = function (length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        vm.compartilhamentoId = result;

        return result;
    }

    if ($location.search().id)
    {
        vm.compartilhamentoId = $location.search().id;
        vm.nomeGrupoCompartilhamento = $location.search().grupo;

        vm.mostrarRanking = true;
        vm.carregarRanking();
    }
    else
    {
        vm.compartilhamentoId = vm.gerarIdCompartilhamento(8);
    }

    vm.qtdeCaracteresRandomicos = 0;

    vm.resultadosSalvosAtuais = [];

    vm.digitacaoExercicio = '';
    vm.digitacaoExercicioReal = '';
    vm.velocidadeAtual = 0;

    vm.cadastrandoEmail = false;


    // remover
    // vm.estagio = 4;

    // vm.velocidadeAtual = 122;
    // vm.info = {
    //     artista: "Wesley Safadão",
    //     codigo: 4,
    //     created_at: "2019-05-27 23:17:34",
    //     erros_aluno: "99.17",
    //     musica_nova: false,
    //     nome: "Aquele 1%",
    //     tempo_aluno: "00:00:19",
    //     texto: "Teste",
    //     updated_at: "2019-05-27 23:17:34",
    //     velocidade_aluno: "378",
    // };
    // vm.teclasErradas = 2;
    // vm.teclasCorretas = 7;
    // vm.tempo_timer = '00:00:07';
    // remover

    vm.timer_rodando = false;
    vm.estagio = 1;
    vm.tempo_timer = '00:00:00';
    vm.snd = new Audio('/sounds/erro3.mp3');
    vm.teclasCorretas = 0;
    vm.teclasErradas = 0;
    vm.teclasErradasAtuais = 0;
    vm.milesimoInicial = null;
    vm.registroTeclas = [];
    vm.textoReplayVoce = '';


    vm.lerHtml = function (snippet) {
        return $sce.trustAsHtml(snippet);
    };
    vm.tocarSomDeErro = function() {
        vm.snd.play();
    };

    vm.gerarNumeroDeCaracteres = function() {
        var min = 16800;
        var max = 30000;
        var random = Math.floor(Math.random() * (+max - +min)) + +min;

        vm.qtdeCaracteresRandomicos = random;
    }

    vm.calcularTempoParaDigitar = function(velocidade) {
        return Math.floor(vm.qtdeCaracteresRandomicos / velocidade);
    }

    vm.gerarLink = function()
    {
        return vm.url + '?id=' +
            vm.compartilhamentoId +
            (vm.nomeGrupoCompartilhamento ? '&grupo=' + encodeURIComponent(vm.nomeGrupoCompartilhamento) : '');
    }

    vm.calcularPaginas = function()
    {
        return Math.floor(vm.qtdeCaracteresRandomicos / 4800);
    }

    vm.qualificadoParaMelhorar = function()
    {
        var analiseVelocidade = vm.info.velocidade_aluno - vm.velocidadeAtual;

        return analiseVelocidade > 0 && analiseVelocidade >= 80;
    }

    vm.calcularPorcentagemAcerto = function(teclasErradas)
    {
        return vm.info ? (Math.round( (100 - ((teclasErradas* 100) / vm.info.texto.length ) ) * 100 + Number.EPSILON ) / 100) : 0;
    }

    vm.copiarLink = function() {
        var text_to_share = vm.gerarLink();

        // create temp element
        var copyElement = document.createElement("span");
        copyElement.appendChild(document.createTextNode(text_to_share));
        copyElement.id = 'tempCopyToClipboard';
        angular.element(document.body.append(copyElement));

        // select the text
        var range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        // copy & cleanup
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        copyElement.remove();

        alert("Copiado. Pressione Ctrl + V para colar.");


    }

    vm.comecarReplayVoce = function(idSequencial) {
        // Digita igual o aluno logado
        if (idSequencialAtual == idSequencial) {

            vm.textoReplayVoce = '';
            setTimeout(function() {
                total = 1;
                var milesimoTotal = 0;
                for (var i = 0; i < vm.registroTeclas.length; i++) {
                    digitar(vm.registroTeclas[i], idSequencial);
                }
    
    
                $timeout(function() {
                    // resetar
                    vm.comecarReplayVoce(idSequencial);
                }, vm.milesimoTotal + 3000);
    
    
        
            }, 3000);
    
        }

        function digitar(tecla, idSequencial) {
            $timeout(function() {
                if (idSequencialAtual == idSequencial) {
                    vm.textoReplayVoce += tecla.tecla.charAt(0);
                }
            }, tecla.milesimo);
        }
    }

    vm.reiniciar = function()
    {
        vm.timer_rodando = false;
        vm.tempo_timer = '00:00:00';
        vm.digitacaoExercicio = '';
        vm.digitacaoExercicioReal = '';

        vm.avaliacao = 0;
        vm.feedback = '';
        vm.textoLabelFeedback = 'O que você achou?';


        vm.teclasCorretas = 0;
        vm.teclasErradas = 0;
        vm.teclasErradasAtuais = 0;

        vm.milesimoInicial = null;
        vm.registroTeclas = [];
        idSequencialAtual++;


        var textarea = document.getElementById('digitacao');
        if (textarea)
            textarea.focus();
    }

    vm.comecarTeste = function(teste)
    {
        if (vm.estagio == 2)
        {
            vm.estagio = 3;

            vm.info = teste;
        }
        else
        {
            if (vm.email.email == '')
                alert('Preenche o seu Email!');
            else
                vm.estagio = 3;
        }

        vm.info = teste;
        vm.carregando = false;
    }

    vm.iniciarPararTimer = function()
    {
        if (!vm.timer_rodando)
        {
            vm.timer_rodando = true;
            vm.tempo_timer = null;
            vm.milesimoInicial = new Date().getTime();

            $timeout(contadorTimer, 1);
        }
        else
        {
            vm.timer_rodando = false;
            // vm.salvar(null);
        }
    };

    function pad (str, max) {
        str = str.toString();
        return str.length < max ? pad("0" + str, max) : str;
    }

    function contadorTimer()
    {
        if (vm.timer_rodando)
        {
            var hora = new Date();

            if (vm.tempo_timer == null || vm.tempo_timer == '')
            {
                vm.tempo_timer = '00:00:00';
            }

            var timer = vm.tempo_timer.split(':');

            timer[2] = parseInt(timer[2]) + 1;

            // segundos
            if (timer[2] == 60)
            {
                timer[2] = 0;
                timer[1] = parseInt(timer[1]) + 1;
            }

            // minutos
            if (timer[1] == 60)
            {
                timer[1] = 0;
                timer[0] = parseInt(timer[0]) + 1;
            }

            vm.tempo_timer =
                pad(timer[0], 2) + ":"
                + pad(timer[1], 2) + ":"
                + pad(timer[2], 2);

            // console.log(vm.tempo_timer);

            $timeout(contadorTimer, 1000);
        }
    }

    vm.timerEmMinutos = function()
    {
        var hora_minuto_segundo = vm.tempo_timer.split(':');

        return (parseInt(hora_minuto_segundo[0]) * 60) +
            parseInt(hora_minuto_segundo[1]) +
            (parseInt(hora_minuto_segundo[2]) / 60);
    };

    vm.darFeedback = function() {
        vm.textoLabelFeedback = 'Salvando...';

        console.log(vm.resultadosSalvosAtuais);
        $http.post('site/dar-feedback', {
            resultado: vm.resultadosSalvosAtuais[vm.resultadosSalvosAtuais.length - 1],
            avaliacao: vm.avaliacao,
            feedback: vm.feedback
        }).then(function successCallback(response){
            //    vm.textoLabelFeedback = 'Obrigado pelo feedback!';
            vm.textoLabelFeedback = 'Obrigado pelo feedback!';
        });


    }

    vm.gravarResultados = function() {
        $http.post('site/gravar-resultados', {
            resultados: vm.resultadosSalvosAtuais,
            nome: vm.email.nome,
            email: vm.email.email,
        }).then(function successCallback(response){
            if (vm.mostrarRanking)
            {
                vm.carregandoTop10 = true;
                $http.post('site/carregar-top10', {id_grupo: vm.compartilhamentoId}).then(function successCallback(response){
                    vm.top10 = response.data;
                    vm.carregandoTop10 = false;
                });
            }
        });


    }

    vm.cadastrarEmails = function()
    {
        if (vm.email.email == '' || vm.email.nome == '')
            alert('Os campos "Email" e "Nome" são obrigatórios!');

        vm.gravarResultados();

        vm.cadastrandoEmail = true;

        vm.email.velocidade = vm.velocidadeAtual || 0;
        vm.email.velocidade = vm.teclasErradas ? vm.calcularPorcentagemAcerto(vm.teclasErradas) : 0;

        $('#easyform_1e2e1TkrehI5cKLFYieca05a808').submit();

        vm.cadastrandoEmail = false;
        vm.agradecimentoEmail = true;
    }

    vm.removerBackspace = function($event)
    {
        if ($event.keyCode === 8)
            if (vm.teclasCorretas > 0)
                vm.teclasCorretas  = vm.teclasCorretas - 1;
    }


    

    vm.teste = function($event)
    {
        vm.mensagemCheater = "";
        $event.preventDefault();
        $event.stopPropagation();

        vm.errando = false;

        if (!vm.timer_rodando)
            vm.iniciarPararTimer();


    
        // console.log($event.key);
        var tecla = $event.key.toString();
        var milesimoAtual = new Date().getTime();
        vm.registroTeclas.push({tecla: tecla, milesimo: (milesimoAtual - vm.milesimoInicial)});
        vm.milesimoTotal = (milesimoAtual - vm.milesimoInicial);

        var teclaLower = $event.key.toString().toLowerCase();

        var posicaoTeste = vm.digitacaoExercicio.length;

        if (teclaLower == 'backspace')
            alert('backspace');

        if (teclaLower != 'control' && teclaLower != 'shift' && teclaLower != 'backspace' && teclaLower != 'dead')
            vm.digitacaoExercicioReal = vm.digitacaoExercicioReal + $event.key.toString();

        if (vm.mensagemCheater == '' && teclaLower != 'control' && teclaLower != 'shift' && teclaLower != 'backspace' && teclaLower != 'dead')
        {
            if (tecla == vm.info.texto.charAt(posicaoTeste))
            {
                vm.digitacaoExercicio = vm.digitacaoExercicio + $event.key.toString();
                vm.teclasCorretas = vm.teclasCorretas + 1;
            }
            else
            {
                vm.tocarSomDeErro();
                vm.teclasErradas = vm.teclasErradas + 1;
                vm.teclasErradasAtuais = vm.teclasErradasAtuais + 1;
                vm.errando = true;
            }
        }


        if (vm.digitacaoExercicio.length == vm.info.texto.length
            && vm.digitacaoExercicio == vm.info.texto
            && vm.digitacaoExercicioReal.length >= vm.digitacaoExercicio.length
            && vm.teclasCorretas >= vm.info.texto.length)
        {

            vm.tempoEmMinutos = vm.timerEmMinutos();

            vm.caracteres = vm.info.texto.length;

            // Jeito antigo de calcular
            // vm.velocidadeAtual = Math.floor((vm.caracteres) / vm.tempoEmMinutos);

            vm.velocidadeAtual = Math.floor(((vm.caracteres) / vm.milesimoTotal) * 60000);

            vm.estagio = 4;

            vm.iniciarPararTimer();
            vm.calcularComparacoes(vm.velocidadeAtual);

            vm.urlGif = '/site/gif?velocidade='+vm.velocidadeAtual;
            vm.comecarReplayVoce(idSequencialAtual);
            console.log(vm.urlGif);

            if (vm.velocidadeAtual <= 999)
                $http.post('site/testes/resultado/salvar', {
                    teste: vm.info.codigo,
                    erros: vm.calcularPorcentagemAcerto(vm.teclasErradas),
                    velocidade: vm.velocidadeAtual,
                    tempo: vm.tempo_timer,
                    id_grupo: vm.compartilhamentoId,
                    nome_grupo: vm.compartilhamentoNome,
                    nome: vm.email.nome,
                    email: vm.email.email

                }).then(function successCallback(response){
                    vm.resultadosSalvosAtuais.push(response.data);

                    if (vm.mostrarRanking)
                    {
                        vm.carregarRanking();
                    }
                });

            // vm.aplicarGoogleChart();

        }
        else {
            if (vm.digitacaoExercicio.length == vm.info.texto.length && vm.digitacaoExercicioReal.length < vm.digitacaoExercicio.length)
                vm.mensagemCheater = 'Que coisa estranha! Parece que o que você digitou está diferente do que está no campo digitação... Por que será?'

        }

        return false;

    };
  
});