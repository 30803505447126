angular.module('HabilidadesService', [])
    .service('$habilidades', function ($http)
    {
        var vm = this;

        vm.habilidades = [
            {codigo: 1, nome: 'Medalha Bronze', nome_curto: 'Bronze', icone: 'mdi-seal', cor: '#ff925d', conquistados: null, total: 0, descricao: 'Velocidade de 10 a 100 tpm', descricao_curta: '10 a 100 tpm',},
            {codigo: 2, nome: 'Medalha Prata', nome_curto: 'Prata', icone: 'mdi-seal', cor: '#a8a8a8', conquistados: null, total: 0, descricao: 'Velocidade de 101 a 150 tpm', descricao_curta: '101 a 150 tpm',},
            {codigo: 3, nome: 'Medalha Ouro', nome_curto: 'Ouro', icone: 'mdi-seal', cor: '#ffca2d', conquistados: null, total: 0, descricao: 'Velocidade de 151 a 200 tpm', descricao_curta: '151 a 200 tpm',},
            {codigo: 4, nome: 'Troféu Bronze', nome_curto: 'Bronze', icone: 'mdi-trophy', cor: '#ff925d', conquistados: null, total: 0, descricao: 'Velocidade de 201 a 250 tpm', descricao_curta: '201 a 250 tpm',},
            {codigo: 5, nome: 'Troféu Prata', nome_curto: 'Prata', icone: 'mdi-trophy', cor: '#a8a8a8', conquistados: null, total: 0, descricao: 'Velocidade de 251 a 300 tpm', descricao_curta: '251 a 300 tpm',},
            {codigo: 6, nome: 'Troféu Ouro', nome_curto: 'Ouro', icone: 'mdi-trophy', cor: '#ffca2d', conquistados: null, total: 0, descricao: 'Velocidade de 301 a 400 tpm', descricao_curta: '301 a 400 tpm',},
            {codigo: 7, nome: 'Troféu Rubí', nome_curto: 'Rubí', icone: 'mdi-trophy', cor: '#f00', conquistados: null, total: 0, descricao: 'Velocidade de 401 a 500 tpm', descricao_curta: '401 a 500 tpm',},
        ];

        vm.get = function() {
            return vm.habilidades;
        }

        vm.find = function(codigo) {
            for (var i = 0; i < vm.habilidades.length; i++) {
                if (vm.habilidades[i].codigo == codigo)
                    return vm.habilidades[i];
            }
        }

        vm.getBackground = function(habilidade) {
            return {
                'background-color': habilidade.cor 
            };
        }

        vm.getProgresso = function(habilidade) {
            return Math.ceil((habilidade.conquistados || 0) * 100 / habilidade.total);
        }

        vm.getProgressoStyle = function(habilidade) {
            return {
                'background-color': habilidade.cor,
                'width': vm.getProgresso(habilidade) + '%'
            };
        }

        vm.getIconeStyle = function(habilidade, concluiu, grande) {
            if (typeof(grande) == 'undefined')
                grande = false;

            return {
                'color': concluiu ? habilidade.cor : null, 
                'filter': concluiu ? 'none' : 'drop-shadow(0px 0px 2px '+habilidade.cor+')',
                'font-size': grande ? '72px' : '18px', 
                'margin-right': '4px'
            };
        }

        vm.getIconeClass = function(habilidade) {
            return {["mdi "+habilidade.icone] : true};
        }

        vm.carregarTotais = function(habilidade) {
            $http.post('treinar/objetivos-totais/' + habilidade.codigo).then(
                function success(resposta) {
                    habilidade.total = resposta.data.total;
                    habilidade.conquistados = resposta.data.conquistados;
                },
                function error() {}
            );
        }
    });